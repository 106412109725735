import { _classes, parse } from "../utilities/helpers";
import styles from "../../styles/partials/floating-buttons.scss";
import { blogIcon, chatBubble } from "../widgets/SVG";
import { useAppState } from "../providers";
import Link from "../widgets/Link";
const cl = _classes(styles);

FloatingButtons.propTypes = {};
export default function FloatingButtons() {
  const { byTemplate, contactSlideOpen, setContactSlideOpen } = useAppState();
  const headerContent = byTemplate("header")[0];
  const contact = headerContent.buttonlink2 && parse(headerContent.buttonlink2);
  const blog = headerContent.buttonlink3 && parse(headerContent.buttonlink3);
  return (
    <div className={cl("_")}>
      {contact.link !== "" && (
        <Link
          className={cl("contact")}
          onClick={() =>
            !contactSlideOpen
              ? setContactSlideOpen(true)
              : setContactSlideOpen(false)
          }
        >
          <span className={cl("icon")}>{chatBubble}</span>
          {contact.text || "Contact"}
        </Link>
      )}
      {blog.link !== "" && (
        <Link target={"_self"} path={blog.link} className={cl("blog")}>
          {blogIcon}
        </Link>
      )}
    </div>
  );
}
