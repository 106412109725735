import Link from "../widgets/Link";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/nav.scss";
import useAppState from "../providers/AppProvider";
import { motion } from "../utilities/FramerMotion";
import SubNav from "./SubNav";

const cl = _classes(styles);

const variants_nav = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 0, transition: { delay: 1 } },
};
const variants_ul = {
  open: {
    transition: { staggerChildren: 0.175, delayChildren: 0.3 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const variants_li = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

Nav.propTypes = {};

function Nav() {
  const { childrenById } = useAppState();
  const items = childrenById(1).filter((item) => item.showinnav === true);

  return (
    <motion.nav className={cl("_")} variants={variants_nav}>
      <motion.ul variants={variants_ul}>
        {items &&
          items.map((item) => {
            const active =
              item.path === window.location.pathname ||
              window.location.pathname.includes(item.path);
            const children = childrenById(item.id);
            const activeChildren = children.filter(
              (item) => item.showinnav === true
            );
            const activeClass = active ? cl("active") : "";
            return (
              <motion.li key={item.id} variants={variants_li}>
                <Link className={activeClass} path={item.path}>
                  {item.linktitle}
                  {activeChildren.length > 0 && (
                    <SubNav
                      className={cl("sub-nav")}
                      children={activeChildren}
                    />
                  )}
                </Link>
              </motion.li>
            );
          })}
      </motion.ul>
    </motion.nav>
  );
}

export default Nav;
