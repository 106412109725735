import { useEffect } from "react";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/header.scss";
import { useAppState, useViewportContext } from "../providers";
import { mainLogo } from "../widgets/SVG";
import CTA from "../widgets/CTA";
import Hamburger from "../partials/Hamburger";
import Link from "../widgets/Link";
import Nav from "./Nav";
import useScrollDirection from "../utilities/hooks/useScrollDirection";
import Modal from "../widgets/Modal";
import ContactForm from "../partials/forms/ContactForm";
import MobileNav from "./MobileNav";
import BookTourForm from "./forms/BookTourForm";

const cl = _classes(styles);

Header.propTypes = {};
export default function Header() {
  const {
    byTemplate,
    scrolled,
    setScrolled,
    tourSlideOpen,
    setTourSlideOpen,
    contactSlideOpen,
    setContactSlideOpen,
    mobileMenuOpen,
  } = useAppState();
  const header = byTemplate("header")[0];
  const { viewport } = useViewportContext();
  const scrollDirection = useScrollDirection();

  const renderLogo = () => (
    <Link path={"/"} className={cl("logo")}>
      {mainLogo}
    </Link>
  );

  const renderCTA = () => (
    <CTA
      className={cl("book-tour")}
      alternate={true}
      path={"#"}
      text={viewport.is("mobile") ? "Book" : "Book a Tour"}
      onClick={() => {
        tourSlideOpen ? setTourSlideOpen(false) : setTourSlideOpen(true);
      }}
    />
  );

  const renderContactForm = () => {
    return (
      <Modal
        visible={contactSlideOpen}
        close={() => {
          contactSlideOpen
            ? setContactSlideOpen(false)
            : setContactSlideOpen(true);
        }}
        onClick={close}
        backgroundColor="darkGreen"
      >
        <ContactForm />
      </Modal>
    );
  };

  const renderTour = () => {
    return (
      <Modal
        visible={tourSlideOpen}
        close={() => {
          tourSlideOpen ? setTourSlideOpen(false) : setTourSlideOpen(true);
        }}
        onClick={close}
      >
        <BookTourForm />
      </Modal>
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrolled(window.scrollY > 50);
      window.scrollY <= 50 && setScrolled(false);
    });
  }, []);

  const renderMobile = () => (
    <>
      <Hamburger />
      {renderLogo()}
      {renderCTA()}
    </>
  );

  const renderDesktop = () => (
    <>
      {renderLogo()}
      <div className={cl("nav-wrapper")}>
        <Nav />
        {renderCTA()}
      </div>
    </>
  );

  return (
    <>
      <header
        className={cl([
          "_",
          scrollDirection === "down" ? "down" : "up",
          scrolled && "scrolled",
          mobileMenuOpen && "mobile-menu-open",
        ])}
      >
        <div
          className={cl([
            "wrapper",
            viewport.is("mobile") ? "mobile" : "desktop",
          ])}
        >
          {viewport.is("mobile") ? renderMobile() : renderDesktop()}
        </div>
        {renderTour()}
        {renderContactForm()}
      </header>
      {viewport.is("mobile") && <MobileNav />}
    </>
  );
}
