import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/sub-nav.scss";
import Link from "../widgets/Link";
const cl = _classes(styles);

SubNav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
};
export default function SubNav({ className, children }) {
  return (
    <div className={`${className !== "" && className} ${cl("_")}`}>
      <ul>
        {children.map((child) => {
          const active =
            child.path === window.location.pathname ||
            window.location.pathname.includes(child.path);
          return (
            <li key={child.id}>
              {child.checkbox1 ? (
                <a
                  className={`${active ? cl("active") : ""} blog`}
                  onClick={() => window.open(child.h2)}
                >
                  {child.linktitle}
                </a>
              ) : (
                <Link className={active ? cl("active") : ""} path={child.path}>
                  {child.linktitle}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
