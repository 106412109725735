import browser from "bowser";
import InvalidBrowser from "./utilities/InvalidBrowser/InvalidBrowser.js";
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import FloatingButtons from "./partials/FloatingButtons";
import APP_CONFIG from "./config/appConfig";
import Routes from "./containers/Routes.js";
import { useEffect } from "react";
import useAppState from "./providers/AppProvider.js";
import CookiePopup from "./partials/CookiePopup";

App.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default function App({ location }) {
  const { byPath } = useAppState();
  useEffect(() => {
    pageChangeRoutine();
    setTimeout(hideLoader, APP_CONFIG.loadingDuration);
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
    pageChangeRoutine();
  }, [location.pathname, location.searchj]);

  const hideLoader = () =>
    (document.querySelector(".site-loader").className +=
      " site-loader--loaded");

  const isValidBrowser = () =>
    !(browser.msie && parseInt(browser.version) < 11);

  const pageChangeRoutine = () => {
    const page = byPath(location.pathname);
    document.title = page.pagetitle || byPath("/").pagetitle;

    window.scrollTo(0, 0);
  };

  const trackPageView = (page) => {
    const { title } = document;
    if (window.ga) {
      ga("send", "pageview", { page, title });
    }
  };

  if (!isValidBrowser()) {
    return <InvalidBrowser />;
  }

  return (
    <div className={`app`}>
      <FloatingButtons />
      <Header />
      <Routes />
      <Footer own={location.pathname === "/own" ? true : false}/>
      <CookiePopup />
    </div>
  );
}
