import { _classes, parse } from "../utilities/helpers";
import styles from "../../styles/partials/footer.scss";
import HeadingAndText from "./HeadingAndText";
import CTA from "../widgets/CTA";
import { useAppState } from "../providers";
import DownloadDropdown from "./DownloadDropdown";
import Link from "../widgets/Link";
import {
  mainLogo,
  instagramIcon,
  facebookIcon,
  youtubeIcon,
  blogIcon,
} from "../widgets/SVG";
const cl = _classes(styles);

Footer.propTypes = {
  own: PropTypes.boolean,
};

function Footer({ own }) {
  const { byTemplate, contactSlideOpen, setContactSlideOpen } = useAppState();

  const footer = byTemplate("footer")[0];
  const parsedContactLink = parse(footer.buttonlink1);

  const renderNav = () => {
    return (
      <nav className={cl("navigation")}>
        <ul>
          {footer.fieldgroup4.map((item, index) => {
            const active =
              window.location.pathname === item.h2 ||
              window.location.pathname.includes(item.path);
            return (
              <li key={index}>
                <Link className={active ? cl("active") : ""} path={item.h2}>
                  {item.h1}
                </Link>
              </li>
            );
          })}
          <li>
            <a
              className={styles.footer__credits}
              href="https://www.onceuponatime.agency/hospitality"
              data-txt="Credits"
              target="_blank"
            >
              <span>Once Upon a Time</span>
            </a>
          </li>
        </ul>
      </nav>
    );
  };
  const renderSocial = () => (
    <ul className={cl("social-icons")}>
      {footer.social_icon1 && (
        <li>
          <Link path={footer.social_icon1}>{instagramIcon}</Link>
        </li>
      )}
      {footer.social_icon2 && (
        <li>
          <Link path={footer.social_icon2}>{facebookIcon}</Link>
        </li>
      )}
      {footer.social_icon3 && (
        <li>
          <Link path={footer.social_icon3}>{youtubeIcon}</Link>
        </li>
      )}
      {footer.social_icon4 && (
        <li>
          <Link target={"_self"} path={footer.social_icon4}>
            {blogIcon}
          </Link>
        </li>
      )}
    </ul>
  );

  return (
    <footer className={cl("_")}>
      <div className={cl("top-footer-wrapper")}>
        <div className={cl("top-footer")}>
          <HeadingAndText title={footer.h2} text={footer.blurb1} />
          <div className={cl("ctas")}>
            {parsedContactLink.text && (
              <CTA
                className={cl("contact")}
                text={parsedContactLink.text}
                onClick={() =>
                  !contactSlideOpen
                    ? setContactSlideOpen(true)
                    : setContactSlideOpen(false)
                }
              />
            )}
            {footer.fieldgroup2.length > 0 && (
              <DownloadDropdown
                buttonText={footer.h3}
                fieldgroup={footer.fieldgroup2}
              />
            )}
            {footer.fieldgroup1.length > 0 && (
              <DownloadDropdown
                buttonText={footer.h4}
                fieldgroup={footer.fieldgroup1}
              />
            )}
          </div>
        </div>
      </div>
      <div className={cl("bottom-footer")}>
        <div
          className={cl("address")}
          dangerouslySetInnerHTML={{ __html: footer.blurb2 }}
        />
        <div className={cl("logo")}>
          <Link path={"/"}>{mainLogo}</Link>
        </div>
        <div className={cl("social-and-nav")}>
          {renderNav()}
          {renderSocial()}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
