import Link from "../widgets/Link";
import { useState } from "react";
import AnimateHeight from "react-animate-height";
import useAppState from "../providers/AppProvider";
import { _classes } from "../utilities/helpers";
import { motion } from "../utilities/FramerMotion";
import styles from "../../styles/partials/mobile-nav.scss";
const cl = _classes(styles);

const variantsnav = {
  open: {
    opacity: 1,
    pointerEvent: "all",
    x: 0,
    transition: { duration: 0.5 },
  },
  closed: {
    opacity: 1,
    transition: { delay: 0.4, x: { duration: 0.4, ease: "easeInOut" } },
    pointerEvent: "none",
    x: "-100%",
  },
};
const variantsul = {
  open: {
    transition: { staggerChildren: 0.05, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.03, staggerDirection: -1 },
  },
};
const variants_li = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 500, velocity: -100 },
    },
  },
  closed: {
    x: -30,
    opacity: 0,
    transition: {
      x: { stiffness: 500 },
    },
  },
};
MobileNav.propTypes = {};

function MobileNav() {
  const { childrenById, mobileMenuOpen, setMobileMenuOpen } = useAppState();
  const [activeSubNav, setActiveSubNav] = useState(null);

  const items = childrenById(1).filter((item) => item.showinnav === true);
  const getChildren = (id) => {
    const children = childrenById(id);
    return children.filter((child) => child.showinnav === true);
  };

  return (
    <motion.nav
      animate={mobileMenuOpen ? "open" : "closed"}
      variants={variantsnav}
      initial="closed"
      className={cl(["_", mobileMenuOpen && "open-nav"])}
    >
      <motion.ul variants={variantsul} className={cl("nav-items")}>
        {items &&
          items.map((item) => {
            const path = item.path;
            const children = childrenById(item.id);
            const hasSubNav = children.filter(
              (child) => child.showinnav === true
            );
            return (
              <motion.li
                variants={variants_li}
                key={item.id}
                className={cl("nav-item")}
              >
                <div className={cl("flex-wrap")}>
                  <Link
                    className={cl(["nav-link"])}
                    onClick={() => setMobileMenuOpen(false)}
                    path={path}
                  >
                    {item.linktitle}
                  </Link>
                  {hasSubNav.length > 0 && (
                    <div
                      onClick={() => {
                        activeSubNav === item.id
                          ? setActiveSubNav(null)
                          : setActiveSubNav(item.id);
                      }}
                      className={cl([
                        "cross",
                        activeSubNav === item.id && "active",
                      ])}
                    />
                  )}
                </div>
                <AnimateHeight height={activeSubNav == item.id ? "auto" : 0}>
                  <ul className={cl("sub-nav")}>
                    {getChildren(item.id).map((child) => {
                      const path = child.path;
                      return (
                        <li key={child.id} className={cl("sub-nav-item")}>
                          {child.checkbox1 ? (
                            <a
                              className={cl("sub-nav-link")}
                              onClick={() => setMobileMenuOpen(false)}
                              href={path}
                              target="_blank"
                            >
                              {child.h1}
                            </a>
                          ) : (
                            <Link
                              className={cl("sub-nav-link")}
                              onClick={() => setMobileMenuOpen(false)}
                              path={path}
                            >
                              {child.h1}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </AnimateHeight>
              </motion.li>
            );
          })}
      </motion.ul>
    </motion.nav>
  );
}

export default MobileNav;
