import Link from "./Link";
import styles from "../../styles/widgets/_cta.scss";

CTA.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  alternate: PropTypes.bool,
  color: PropTypes.oneOf(["white", "black"]),
};

CTA.defaultProps = {
  link: "",
  text: "",
  onClick: () => null,
  className: "",
};

function CTA({ link, text, onClick, className, alternate, color }) {
  return (
    <Link
      className={`${className} ${styles.cta} ${
        color === "white" && styles.white
      } ${alternate && styles.alternate}`}
      path={link}
      ariaLabel={text}
      onClick={() => {
        onClick();
      }}
    >
      {text}
    </Link>
  );
}

export default CTA;
