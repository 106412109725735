import React from "react";
import styles from "../../../styles/partials/forms/contact-form.scss";
import { _classes } from "../../utilities/helpers";
import { useAppState } from "../../providers";
import HubspotForm from "./HubspotForm";

export default function ContactForm(props, color) {
  const { setContactSlideOpen, byTemplate } = useAppState();
  const contact = byTemplate("contact")[0];
  const cl = _classes(styles);
  return (
    <div className={cl("_")}>
      <div className={cl("close")} onClick={() => setContactSlideOpen(false)}>
        CLOSE X
      </div>

      <div className={cl("form-wrapper")}>
        <div className={cl("left-col")}>
          <div
            className={cl("contact-info")}
            dangerouslySetInnerHTML={{ __html: contact.blurb1 }}
          />
        </div>

        <div className={cl("right-col")}>
          <HubspotForm
            formId="46772487-5e67-451e-b7f6-e0ec884568e5"
            className="contact"
          />
        </div>
      </div>
    </div>
  );
}
