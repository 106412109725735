import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/heading-and-text.scss";
import Reveal from "../widgets/Reveal";
const cl = _classes(styles);

HeadingAndText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  largeText: PropTypes.bool,
  alignment: PropTypes.oneOf(["left", "right"]),
  noPadding: PropTypes.bool,
  activityName: PropTypes.bool,
  smallWidth: PropTypes.bool,
};
export default function HeadingAndText({
  title,
  text,
  largeText,
  alignment,
  className,
  noPadding,
  activityName,
  smallWidth,
}) {
  return (
    <div
      className={`${className && className} ${cl([
        "_",
        alignment && alignment === "right"
          ? "right"
          : alignment === "left" && "left",
        noPadding && "no-padding",
        smallWidth && "small-width",
      ])}`}
    >
      {title && (
        <Reveal>
          <h2 className={cl("title")}>{title}</h2>
        </Reveal>
      )}
      {activityName ? (
        <div className={cl("activity")}>{activityName}</div>
      ) : null}
      {text && (
        <Reveal>
          <div
            className={cl([
              "content",
              largeText ? "large" : "",
              activityName ? "activityParagraph" : "",
            ])}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Reveal>
      )}
    </div>
  );
}
