import React, { useState } from "react";
import styles from "../../../styles/partials/forms/book-tour-form.scss";
import { useAppState } from "../../providers";
import { _classes } from "../../utilities/helpers";
import { motion } from "../../utilities/FramerMotion";
import HubspotForm from "./HubspotForm";

export default function BookTourForm() {
  const { tourSlideOpen, setTourSlideOpen } = useAppState();

  const cl = _classes(styles);

  const variants = {
    animate: {
      x: !tourSlideOpen ? 600 : 0,
      transition: { duration: 0.3, times: [0, 0.2, 1] },
    },
    initial: { x: 600 },
  };

  const renderForm = () => {
    return (
      <motion.div className={cl("_")} {...variants}>
        <div
          className={cl("close")}
          onClick={() => {
            tourSlideOpen ? setTourSlideOpen(false) : setTourSlideOpen(true);
          }}
        >
          CLOSE X
        </div>

        <div className={cl("title")}>Book a Tour</div>

        <HubspotForm
          formId="24ae7191-a223-4835-94e5-d66fa6d8e684"
          bookTour={true}
        />
      </motion.div>
    );
  };

  return <div>{renderForm()}</div>;
}
