// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sub-nav______1xA2_{background:#fff;font-size:.875rem;position:absolute}.sub-nav______1xA2_ ul{padding:10px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.sub-nav______1xA2_ a{padding:5px 0;white-space:nowrap;display:block}.sub-nav______1xA2_ .sub-nav__active___1w7No{text-shadow:0 0 1px #1d1c1c}", "",{"version":3,"sources":["webpack://./src/styles/partials/sub-nav.scss"],"names":[],"mappings":"AAEA,oBACE,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,uBACE,YAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAEF,sBACE,aAAA,CACA,kBAAA,CACA,aAAA,CAEF,6CACE,2BAAA","sourcesContent":["@import \"../global/parts\";\n\n._ {\n  background: $white;\n  font-size: 0.875rem;\n  position: absolute;\n  ul {\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n  }\n  a {\n    padding: 5px 0;\n    white-space: nowrap;\n    display: block;\n  }\n  .active {\n    text-shadow: 0 0 1px $black;\n  }\n}\n\n@include _notAccessible {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": "sub-nav______1xA2_",
	"active": "sub-nav__active___1w7No"
};
export default ___CSS_LOADER_EXPORT___;
