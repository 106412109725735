import { useState } from "react";
import { _classes, parse } from "../utilities/helpers";
import styles from "../../styles/partials/download-dropdown.scss";
import Link from "../widgets/Link";
import CTA from "../widgets/CTA";
const cl = _classes(styles);

DownloadDropdown.propTypes = {
  className: PropTypes.string,
  fieldgroup: PropTypes.array.isRequired,
  buttonText: PropTypes.string,
};
export default function DownloadDropdown({
  className,
  fieldgroup,
  buttonText,
}) {
  const [isOpen, setIsOpen] = useState();
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${className} ${cl("_")}`}>
      <CTA onClick={() => handleClick()} text={buttonText || "Download"} />
      <ul className={cl(["list", isOpen && "open"])}>
        {fieldgroup.map((item) => {
          return (
            <li className={cl("item")} key={item.id}>
              <Link
                onClick={() => handleClick()}
                path={parse(item.buttonlink1).link}
                title={parse(item.buttonlink1).text}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
