import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/hamburger.scss";
import { useAppState } from "../providers";
const cl = _classes(styles);

Hamburger.propTypes = {};
export default function Hamburger() {
  const { mobileMenuOpen, setMobileMenuOpen } = useAppState();

  const handleClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  return (
    <div
      className={`${cl("_")} ${mobileMenuOpen && cl("open")}`}
      onClick={() => handleClick()}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
